import React, { useEffect, useRef } from 'react';
import './assets/sass/main.scss';
import Home from './pages/Home';
import Header from './Header';
import Footer from './Footer';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Login from './pages/Login';
import Contact from './pages/Contact';
import ClientPortal from './pages/ClientPortal';
import UserManagement from './pages/UserManagement/UserManagement';
import {ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Alert from './elements/Alert';
import Services from './pages/Services';
import ChangePassword from './pages/UserManagement/ChangePassword';
import Publications from './pages/Publications';


function App() {

  const [bodyClass, setBodyClass] = React.useState("is-mobile");
  const [apolloClient, setApolloClient] = React.useState(null);
  const node = useRef();
  const [alertText, setAlertText] = React.useState("");

  function setupClient(accessToken, client, uid) {
    const httpLink = createHttpLink({
      uri: '/graphql',
    });

    const authLink = setContext((_, { headers }) => {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          "access-token": accessToken,
          "client": client,
          "uid": uid
        }
      }
    });
    
    const apolloClient = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    });

    setApolloClient(apolloClient);
  }

  function handleLogout() {

    // Logout
    apolloClient.mutate({
      mutation: gql`
          mutation {
            userLogout {
              authenticatable {
                id
              }
            }
          }
			`
		}).catch(error => {
      setAlertText(error.message);
			localStorage.removeItem("expiry");
		}).finally(() => {
      localStorage.removeItem("expiry");
      localStorage.removeItem("client");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("uid");
    });

    setApolloClient(null);
    handleMenuClick();
  }

  function handleMenuClick() {
    if (bodyClass === "is-mobile is-menu-visible") {
      setBodyClass("is-mobile");
    } else {
      setBodyClass("is-mobile is-menu-visible");
    }
  }

  function handleOutsideClicking(e) {
    if(node.current.contains(e.target)) {
      return;
    }
    setBodyClass("is-mobile");
  }



	function verifyCredentials() {
		let client = localStorage.getItem("client");
		let token = localStorage.getItem("accessToken");
		let uid = localStorage.getItem("uid");


    const httpLink = createHttpLink({
      uri: '/graphql',
    });
    
    const authLink = setContext((_, { headers }) => {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          "access-token": token,
          "client": client,
          "uid": uid
        }
      }
    });
    
    new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    }).query({
			query: gql`
				query {
					currentUser {
            id
						name
					}
				}
			`
		}).then(result => {
			setupClient(token, client, uid);
		})
		.catch(error => {
			setAlertText(error.message);
			localStorage.removeItem("expiry");
		});
	}

  useEffect(() => {
    let expiry = localStorage.getItem("expiry");
    if(expiry !== null && expiry < Date.now()) {
      verifyCredentials();	
    }
  }, []);

  function getRoutes() {
    let routes = [];
    routes.push(
      <Route exact path="/">
        <Home/>
      </Route>
    );
    routes.push(
      <Route path="/services">
        <Services/>
      </Route>
    );
    routes.push(
      <Route path="/publications">
        <Publications/>
      </Route>
    );
    routes.push(
      <Route path="/changePassword">
        <ChangePassword/>
      </Route>
    )
    routes.push(<Route exact path="/login">
                  <Login onSuccessCallback={setupClient}/>
                </Route>);
    routes.push(<Route exact path="/contact">
                  <Contact/>
                </Route>);
    if(apolloClient !== null) {
      routes.push(<ApolloProvider client={apolloClient}>
                    <Route path="/users">
                      <UserManagement/>
                    </Route>
                    <Route path="/portal">
                      <ClientPortal/>
                    </Route>
                  </ApolloProvider>
      );
    } else {
      routes.push(
      <Route path="/users">
        <Login onSuccessCallback={setupClient}/>
      </Route>);
      routes.push(
      <Route path="/portal">
        <Login onSuccessCallback={setupClient}/>
      </Route>);
    }
    return routes;
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClicking);
    return function cleanup () {
      document.removeEventListener('mousedown', handleOutsideClicking);
    };
  }, [])

  function getClientInteractionButton() {
    if(apolloClient === null) { //Login
      return <li><Link onClick={handleMenuClick} to="/login">Client Login</Link></li>
    } else { //Logout
      return <li><Link onClick={handleLogout} to="/">Log out</Link></li>
    }
  }

  function onAlertClose() {
    setAlertText("");
  }
  
  return (
    <body className={bodyClass} style={{height: "100%"}}>
      <Router>
			<div id="page-wrapper" style={{height: "100%", flexDirection: "column", display: "flex"}}>
        <div style={{flex: "1 0 auto"}}>
        <Header onMenuClick={handleMenuClick}/>
        <Alert show={alertText !== ""} onClick={onAlertClose}>{alertText}</Alert>
        <Switch>
          {getRoutes()}  
        </Switch>
        </div>
        <Footer/>
      </div>

      <div id="menu" ref={node}>
        <ul>
          <li><Link onClick={handleMenuClick} to="/">Home</Link></li>
          <li><Link onClick={handleMenuClick} to="/publications">Publications</Link></li>
          <li><Link onClick={handleMenuClick} to="/contact">Contact</Link></li>
          {apolloClient !== null && <li><Link onClick={handleMenuClick} to="/portal">Client Portal</Link></li> }
          {getClientInteractionButton()}
        </ul>
        <a onClick={handleMenuClick} className="close"></a>
      </div>
      </Router>
    </body>
  );
}

export default App;
