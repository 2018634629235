import React from 'react';
import { useQuery, gql } from '@apollo/client';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import CompanyList from './Company/CompanyList';
import CompanyDashboard from './Company/CompanyDashboard';
import ProjectList from './Company/Projects/ProjectList';

export default function ClientPortal() {
	const IS_ADMIN_QUERY=gql`
		query isUserAdmin {
			currentUser {
				id
				isAdmin
				companies {
					id
				}
			}
		}
	`;

	const { loading, error, data } = useQuery(IS_ADMIN_QUERY);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :-(</p>;


	function getUserManagementPanel() {
		if(data.currentUser.isAdmin) {
			return (<div><h3>Administrator Tasks</h3><h4>User Management</h4><Link to="/users">Go To User Management</Link></div>);
		} else {
			return (
				<div>
					<Link to={`/users/${data.currentUser.id}`}>Manage User Account</Link>
				</div>
			);
		}
	}

	function getContent() {
		if(data.currentUser.companies.length === 0) { // Show warning
			return <p>Your account is not associated with any projects. Please contact the system administrator.</p>
		} else { // you are Admin
			return <CompanyList/>;
		}
	}

	return (

		<article id="main">
			<section className="wrapper style5">
				<div className="inner">
					<div style={{textAlign: "center", backgroundColor: "salmon", borderRadius: "1em", marginTop: "-50px", padding: "0px"}}>
						<p>This site is currently in beta testing. Please report issues to <a href="mailto:webmaster@cosci-llc.com">webmaster@cosci-llc.com</a></p>
					</div>
					<Switch>
						<Route path={`/portal/:companyID`}>
							<CompanyDashboard/>
						</Route>
						<Route path={`/portal`}>
							{getContent()}
							<hr/>
							{getUserManagementPanel()}

						</Route>
					</Switch>	
				</div>
			</section>
		</article>
	);
}