import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import Slideshow from '../elements/Slideshow';


export default function Services() {
	let url= useRouteMatch().url;
	return (
		<article id="main">
			<Slideshow>
				<h2>CoSci LLC</h2>
				<p><b>Consulting in Earth Sciences</b></p>
			</Slideshow>
			<section class="wrapper style5">
				<div class="inner">
				<div style={{textAlign: "center", backgroundColor: "salmon", borderRadius: "1em", marginTop: "-50px", padding: "0px"}}>
						<p>This site is currently in beta testing. Please report issues to <a href="mailto:webmaster@cosci-llc.com">webmaster@cosci-llc.com</a></p>
					</div>
					<Switch>
						<Route path={`${url}/glaciology/`}>
							<div>
								<h2> Glaciology </h2>
								<ul>
									<li> Numerical modeling of glacier and ice-sheet flow </li>
									<li> Glacier-permafrost interaction </li>
									<li> Glacier-groundwater interaction </li>
								</ul>
							</div>
						</Route>
						<Route path={`${url}/geomorphology/`}>
							<div>
								<h2> Geomorphology </h2>
								<ul>
									<li>Glacial erosion processes </li>
									<li>River bank erosion </li>
									<li>Landslides (see Natural Hazards) </li>
								</ul>
							</div>
						</Route>
						<Route path={`${url}/hydrogeology/`}>
							<div>
								<h2> Hydrogeology </h2>
								<ul>
									<li> Numerical model of paleohydrogeological systems </li>
									<li> Hydrogeology of continental shelves </li>
									<li> Offshore hydrogeology and geomorphology (<a target="_blank" href="http://marcan.eu">MARCAN</a>) </li>
									<li> Offshore freshwater (<a target="_blank" href="https://integra-network.weebly.com/">INTEGRA</a>) </li>
								</ul>
							</div>
						</Route>
						<Route path={`${url}/naturalHazards/`}>
							<div>
								<h2> Natural Hazards </h2>
								<ul>
									<li> Software contributor to <a target="_blank" href="https://www.ecorisq.org">ecorisQ</a> </li>
									<li> Slope stability modeling </li>
									<li> Shallow landslide modeling </li>
									<li> Role of vegetation (tree roots) in slope stability </li>
									<li> Fiber bundle model </li>
                </ul>
							</div>
						</Route>

					</Switch>
					
				</div>
			</section>
		</article>
	);
}
