import React from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import Button from '../../elements/Button';
import Alert from '../../elements/Alert';
import { Link } from 'react-router-dom';
import CRUDList from '../../elements/CRUDList';

const LIST_COMPANIES = gql`
	query ListCompanies {
		currentUser {
			id
			isAdmin
			companies {
				id
				name
			}
		}
	}
`;

const ADD_COMPANY = gql`
	mutation AddCompany($name: String!) {
		createCompany(input: {
			name: $name,
			logoURL: ""
		}) {
			company {
				id
				name
			}
		}
	}
`;

const UPDATE_COMPANY = gql`
	mutation UpdateCompany($id: ID!, $name: String!) {
		updateCompany(input: {
			companyID: $id,
			name: $name,
			logoURL: "",
		}) {
			company {
				name
				id
			}
		}
	}
`;

const DELETE_COMPANY = gql`
	mutation DeleteCompany($id: ID!) {
		deleteCompany(input: {
			companyID: $id
		}) {
			success,
			errors
		}
	}
`;


export default function CompanyList() {
	const { loading, error, data, refetch } = useQuery(LIST_COMPANIES);
	const [showAddNew, setShowAddNew] = React.useState(false);
	const [addCompanyHook ] = useMutation(ADD_COMPANY);
	const [updateCompanyHook ] = useMutation(UPDATE_COMPANY);
	const [deleteCompanyHook ] = useMutation(DELETE_COMPANY);
	const [alertText, setAlertText] = React.useState("");
	

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :(</p>;
	
	function onAlertClose() {
		setAlertText("");
	}
	
	function createCompany(companyName) {
		addCompanyHook({variables: {name: companyName}}).then(result => {
			refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
		setShowAddNew(false);
	}

	function updateCompany(companyID, companyName) {
		updateCompanyHook({variables: {id: companyID, name: companyName}}).then(result => {
			refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
	}

	function deleteCompany(companyID) {
		deleteCompanyHook({variables: {id: companyID}}).then(result => {
			refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
	}

	if(data.currentUser.companies.length === 0) {
		return <div>
			<h4>Companies</h4>
			<p>You haven't been added to any companies. Please contact the system administrator</p>
		</div>
	}

	return (
		<div>
			<Alert show={alertText !== ""} onClick={onAlertClose}>{alertText}</Alert>
			<h4>Companies</h4>
				<CRUDList
					isAdmin={data.currentUser.isAdmin}
					title=""
					data={data.currentUser.companies}
					name={(company) => {return company.name}}
					id={(company) => { return company.id}}
					entry={(id, name) => {return <Link to={`/portal/${id}`}>{name}</Link>}}
					showCreateEntry={showAddNew}
					cancelCreateEntry={() => setShowAddNew(false)}
					createEntry={createCompany}
					updateEntry={updateCompany}
					deleteEntry={deleteCompany}
				/>
				{data.currentUser.isAdmin && <Button onClick={() => setShowAddNew(true)}>Add New Company</Button>}
		</div>
	);
}