import React, {useEffect, useState} from 'react';
import { ApolloClient, createHttpLink, InMemoryCache, gql, HttpLink, from, ApolloLink } from '@apollo/client';
import CRUDList from '../../elements/CRUDList';
import Button from '../../elements/Button';
import { Link, useRouteMatch, Redirect, Route } from 'react-router-dom';
import Textbox from '../../elements/Textbox';
import { setContext } from '@apollo/client/link/context';

/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */
var getParams = function (url) {
	var params = {};
	var parser = document.createElement('a');
	parser.href = url;
	var query = parser.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
};

export default function ChangePassword() {
	const	[ shouldRedirect, setShouldRedirect] = useState(false);
	let  url = useRouteMatch().url;
	let params = getParams(window.location.href);
	const [name, setName] = React.useState("");
	const token = params["reset_password_token"];
	const [retrievedHeaders, setHeaders] = React.useState({});
	const [error, setError] = React.useState("");
	const [userErrorText, setUserErrorText] = React.useState("");
	const [userData, setUserData] = useState({ password: "", passwordConfirm: ""});
	

	const afterwareLink = new ApolloLink((operation, forward) => {
		return forward(operation).map(response => {
			const context = operation.getContext()
			const {
				response: { headers }
			} = context
	
			if (headers) {
				console.log(headers.get("client"));
				setHeaders(
					{
						"access-token" : headers.get("access-token"),
						"client": headers.get("client"),
						"uid" : headers.get("uid")
					}
				);
			}
			return response
		})
	})

	function validateToken() {
		const httplink = new HttpLink({uri: "/graphql"});
		const client = new ApolloClient({
			link: from([afterwareLink, httplink]),
			cache: new InMemoryCache(),
			request: async (operation) => {
				console.log(operation);
				console.log("Hello");
			}
		});
		client.query({
			query: gql`
				query CheckToken {
					userCheckPasswordToken(
						resetPasswordToken: "${token}"
					) {
						email,
						name
					}
				}
			`
		}).then(result => {
			setName(result.data.userCheckPasswordToken.name);
			console.log(result);
			console.log("Success!");
		}).catch(error => {
			setError("Invalid Token. Please request a new token from the system administrator");
		});
	}

	useEffect(() => {
		validateToken();
	}, []);

	if(shouldRedirect) return <Redirect to={`/login`}/>


	function updatePassword() {
		const httpLink = createHttpLink({
      uri: '/graphql',
    });

    const authLink = setContext((_, { headers }) => {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          "access-token": retrievedHeaders['access-token'],
          "client": retrievedHeaders.client,
          "uid": retrievedHeaders.uid
        }
      }
    });
		const client = new ApolloClient({
			link: authLink.concat(httpLink),
			cache: new InMemoryCache()
		});
		client.mutate({
			mutation: gql`
				mutation UpdatePassword{
					userUpdatePassword(passwordConfirmation: "${userData.passwordConfirm}", 
										password:"${userData.password}") 
					{
						authenticatable {
							id
							name
						}
					}
				}
			`
		}).then(result => {
			console.log(result);
			console.log("Success With Updates!")
			setShouldRedirect(true);
		})
			.catch(error => {
				console.log("Error submitting");
				console.log(error);
			});
	}
	


	function handleTextBoxOnChange(event) {
		setUserData({...userData, [event.target.name]: event.target.value});
	}

	function contentWrapper(content) {
		return (
			<article id="main">
				<section className="wrapper style5">
					<div className="inner">
						{content}
					</div>
				</section>
			</article>
		);
	}

	if (error !== "") return contentWrapper(<p>{error}</p>);
	if (name === "") return contentWrapper(<p>Loading....</p>);
	//if (loading) return <p>Loading...</p>;
	//if (error) return <p>Error :(</p>;

	

	function onClickSave() {
		setUserErrorText("");
		if(userData.password !== userData.passwordConfirm) {
			setUserErrorText("Please make sure passwords are identical!");
			return;
		}
		updatePassword();
		//Submit the new password
	}

	return (
		contentWrapper(
			<div>
				<h4>Welcome {name}!</h4>
				<h6>Change Password</h6>
				
				<p>Password: </p><Textbox onChange={handleTextBoxOnChange} name="password"  value={userData.password} placeholder="Password" type="password"/>
				<hr/>
				<p>Password Confirmation: </p><Textbox onChange={handleTextBoxOnChange} name="passwordConfirm"  value={userData.passwordConfirm} placeholder="Password Confirmation" type="password"/>
				<hr/>
				{userErrorText !== "" && userErrorText}
				<br/>
				<Button onClick={onClickSave}>Update Password</Button>
			</div>
		)
	);
}