import React from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import Button from '../../../../../elements/Button';
import { Link, useParams } from 'react-router-dom';
import CollapsibleList from '../../../../../elements/CollapsibleList';
import AddFileControl from './AddFileControl';
import CRUDGrid from '../../../../../elements/CRUDGrid';

import PDFIcon from './Icons/pdf-5.svg';
import DataIcon from './Icons/excel-5.svg';
import PictureIcon from './Icons/067-pictures.svg';

const LIST_FILES_FOR_TASK = gql`
	query ListFiles($company: ID!, $project: ID!, $task: ID!) {
		fileCategories {
			id
			name
		}
		currentUser {
			id
			isAdmin
			company(id: $company) {
				id
				project(id: $project) {
					id
					task(id: $task) {
						id
						files {
							id
							name
							fileCategory {
								name
							}
						}
					}
				}
			}
		}
	}`;


const LIST_FILES_FOR_PROJECT = gql`
	query ListFiles($company: ID!, $project: ID!) {
		fileCategories {
			id
			name
		}
		currentUser{
			id
			isAdmin
			company(id: $company) {
				id
				project(id: $project) {
					id
					files {
						id
						taskId
						name
						fileCategory {
							name
						}
					}
				}
			}
		}
	}`;

const UPDATE_FILE = gql`
	mutation UpdateFile($companyID: ID!, $projectID: ID!,  $taskID: ID!, $fileID: ID!, $name: String!) {
		updateFile(input: {
			companyID: $companyID,
			projectID: $projectID,
			taskID: $taskID,
			fileID: $fileID,
			name: $name
		}) {
			file {
				id
				name
			}
		}
	}
`;

const DELETE_FILE = gql`
	mutation DeleteFile($companyID: ID!, $projectID: ID!, $taskID: ID!, $fileID: ID!) {
		deleteFile(input: {
			companyID: $companyID,
			projectID: $projectID,
			taskID: $taskID,
			fileID: $fileID
		}) {
			success,
			errors
		}
	}
`;

export default function FileList(props) {
	let { companyID, projectID, taskID } = useParams();
	const { loading, error, data, refetch } = useQuery(props.useProject ? LIST_FILES_FOR_PROJECT : LIST_FILES_FOR_TASK, {variables: {company: companyID, project: projectID, task: taskID}});
	const [showAddNew, setShowAddNew] = React.useState(false);
	const [updateHook ] = useMutation(UPDATE_FILE);
	const [deleteHook ] = useMutation(DELETE_FILE);
	

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :(</p>;


	function addObjectElements() {
		if(showAddNew) {
			return (
				<AddFileControl
					companyID={companyID}
					projectID={projectID}
					taskID={taskID}
					onAdd={() => { setShowAddNew(false); refetch(); }}
					fileCategories={data.fileCategories}
				/>
			);
		} else {
			if(props.useProject || !data.currentUser.isAdmin) {
				return;
			}
			return <Button style={{ marginTop: "10px"}} onClick={() => setShowAddNew(true)}>Add New File</Button>
		}
	}

	// Group everything
	let groups = {};
	let returnedFiles;
	if (props.useProject) {
		returnedFiles = data.currentUser.company.project.files;
	} else {
		returnedFiles = data.currentUser.company.project.task.files;
	}

	for(const file of returnedFiles) {
		if(groups[file.fileCategory.name] === undefined) {
			groups[file.fileCategory.name] = [];
		}
		groups[file.fileCategory.name].push(file);
	}

	function updateFile(fileID, fileName) {
		updateHook({variables: {companyID: companyID, 
														projectID: projectID, 
														taskID: taskID,
														fileID: fileID,
														name: fileName
													}}).then(result => {
			refetch();
		}).catch(error => {
			alert(error);
		});
	}

	function deleteFile(fileID) {
		deleteHook({variables: {companyID: companyID, 
														projectID: projectID,
														taskID: taskID,
														fileID: fileID		
													}}).then(result => {
			refetch();
		}).catch(error => {
			alert(error);
		});
	}

	function getDownloadFile(fileID) {
		let client = localStorage.getItem("client");
		let token = localStorage.getItem("accessToken");
		let uid = localStorage.getItem("uid");
		
		return `/download/${companyID}/${projectID}/${taskID}/${fileID}?access-token=${token}&uid=${uid}&client=${client}`;
	}

	function getIcon(entry) {
		switch(entry.fileCategory.name) {
			case 'Reports':
				return PDFIcon;
			
			case "Results":
			case 'Data':
				return DataIcon;

			case "Images":
				return PictureIcon;
		
			default:
				return null;
		}
	}

	function createTable(title, files) {
		return (
			<CollapsibleList title={title} key={title + "collapsible"}>

			<CRUDGrid
				isAdmin={data.currentUser.isAdmin}
				title=""
				data={files}
				name={(file) => {return file.name}}
				id={(file) => { return file.id}}
				entry={(id, name) => {return <Link download target="_blank" to={getDownloadFile(id)}>{name}</Link>}}
				showCreateEntry={false}
				cancelCreateEntry={null}
				createEntry={null}
				updateEntry={updateFile}
				deleteEntry={deleteFile}
				icon={getIcon}
			/>
			</CollapsibleList>
	);
	}

	function createTables(groups)
	{
		const result = [];
		for (const files in groups) {
			result.push(createTable(files, groups[files]));
		}
		return result;
	}

	return (
		<div>
			{createTables(groups)}
			{addObjectElements()}		
		</div>
	);
}
