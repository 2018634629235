import React from 'react';
import Textbox from './Textbox';
import Button from './Button';
import UnknownIcon from '../images/text-5.svg';
import EditIcon from '../images/pencil.svg';
import DeleteIcon from '../images/trash.svg';
import CheckedIcon from '../images/088-checked.svg';
import './CRUDGrid.css';

export default function CRUDGrid(props) {
	const [editID, setEditID] = React.useState(false);
	const [editName, setEditName] = React.useState("");
	const [deleteID, setDeleteID] = React.useState(false);

	function handleEditTextboxOnChange(e) {
		setEditName(e.target.value);
	}

	function editObjectElements(e, id, name) {
		e.stopPropagation();
		if (id === editID) { // Saving the entry
			props.updateEntry(id,editName);
			setEditID(false);
			setEditName("");
		} else {
			setDeleteID(false);
			setEditName(name);
			setEditID(id);
		}
	}
	
	function deleteObject(id) {
			props.deleteEntry(id);
	}

	function getListItem(id,  name) {
		if(id === editID) {
			return (
				<Textbox onChange={handleEditTextboxOnChange} value={editName} type="text" name="editBox" />
			);
		} else {
			return name;
		};
	}	

	function getEditButton(id, name) {
		if(props.updateEntry && props.isAdmin) {
			return <button className="iconbtn" onClick={(e) => editObjectElements(e, id, name)}><img className="iconimg" src={editID === id ? CheckedIcon : EditIcon}/></button>
		}
	}

	function getDeleteButton(id) {
		if(props.deleteEntry  && props.isAdmin) {
			return <button className="iconbtn" onClick={() => deleteObject(id)}><img className="iconimg" src={DeleteIcon}/></button>
		}
	}

	function getIcon(entry) {

		if(props.icon === undefined || props.icon === null)
			return UnknownIcon;

		const icon = props.icon(entry);
		if(icon === null || icon === undefined)
			return UnknownIcon;
		
		return icon;
	}	

	function getTitle(id, name) {
		if(id == editID) {
			return (
				<div className="row gtr-50 gtr-uniform">
					<div className="col-9">
						{getListItem(id, name)}
					</div>
					<div className="col-3">
						{ getEditButton(id, name) }
					</div>
				</div>
			);
		} else {
			return (<div style={{ display: "block", wordWrap: "break-word"}}>
				{getListItem(id, name)}
			</div>);
		}

	}

	return (
		<div>
			<h5>{props.title}</h5>
			<div className="box alt">
				<div className="row gtr-50 gtr-uniform">

					{
						props.data.map((entry) => {
							const id = props.id(entry);
							const name = props.name(entry);
							const currentEdit = id == editID;
							
							let content = (
									<div style={{padding: "0px"}}>
											
										<img className="iconimg" src={getIcon(entry)} alt=""/>
										{!currentEdit && getTitle(id,name)}
									</div>
									
								
							);
							return (
								<div className={currentEdit ? "col-6" : "col-2 col-3-small col-4-xsmall gridItem"} key={id}>
									
									<span className="image fit" style={{padding: "0px"}}>
									{props.entry(id, content)}
									{currentEdit && getTitle(id, name)}
									<div style={{ padding: "0px", marginRight: "15px", marginBottom: "15px"}} className="row gtr-50 gtr-uniform">
										<div className="col-6 col-0-small"/>
										<div className="col-3 col-6-xsmall">
											{ !currentEdit && getEditButton(id, name) }
										</div>
										<div className="col-3 col-6-xsmall">
											{ getDeleteButton(id) }
										</div>
									</div>
									</span>
								</div>
							);
						})

					}
				</div>
			</div>
		</div>		
	);
}

