import React from 'react';
import gla02 from '../images/gla02.jpg';
import geomorph02b from '../images/geomorph02b.jpg';
import hydro01b from '../images/hydro01b.jpg';
import nh01 from '../images/nh01.jpg';
import { Link } from 'react-router-dom';
import Slideshow from '../elements/Slideshow';


const headerStyle = {
	marginBottom: 0
};


export default function Publications() {

	return (
		<article id="main">
			<Slideshow>
				<h2>CoSci LLC</h2>
				<p><b>Consulting in Earth Sciences</b></p>
			</Slideshow>
			<section className="wrapper style5">
				<div className="inner">
					<div style={{textAlign: "center", backgroundColor: "salmon", borderRadius: "1em", marginTop: "-50px", padding: "0px"}}>
						<p>This site is currently in beta testing. Please report issues to <a href="mailto:webmaster@cosci-llc.com">webmaster@cosci-llc.com</a></p>
					</div>
					<h2>Selected publications</h2>
					<div className="box alt">
						<div className="col gtr-50 gtr-uniform">
							<h4>Glaciology</h4>
							<ul>
								<li><strong>Cohen, D.</strong>, F. Gillet-Chaulet, W. Haeberli, H. Machguth, and U. H. Fischer (2018), Numerical reconstructions of the flow and basal conditions of the Rhine Glacier, European Central Alps, at the Last Glacial Maximum, <i>The Cryosphere</i>, 12, 2515--2544, <a target="_blank" href="https://doi.org/10.5194/tc-12-2515-2018">doi.org/10.5194/tc-12-2515-2018</a>.</li>
								<li>Moore, P. L., J. P. Winberry, N. R. Iverson, K. A. Christianson, S.  Anandadrikshnan, M. Jackson, M. E. Mathison, and <strong>D. Cohen</strong> (2013), Glacier slip and seismicity induced by surface melt, <i> Geology</i>, 41, 12, 1247--1250, <a target="_blank" href="https://doi.org/10.1130/G34760.1">doi.org/10.1130/G34760.1</a>.</li>
								<li>Byers, J., <strong>D. Cohen</strong>, and N. R. Iverson (2012), Subglacial clast/bed contact forces, <i> J. Glaciol.</i>, 58, 207, 89--98, <a target="_blank" href="https://doi.org/10.3189/2012JoG11J126">doi.org/10.3189/2012JoG11J126</a>.</li>
								<li>Gagliardini, O., <strong>D. Cohen</strong>, P. Raback, and T. Zwinger (2007), Finite-element modeling of subglacial cavities and related friction law, <i> J. Geophys. Res.</i>, 112, F02027, <a target="_blank" href="https://doi.org/10.1029/2006JF000638">doi.org/10.1029/2006JF000638</a>.</li>
								<li><strong>Cohen D.</strong>, T. S. Hooyer, N. R. Iverson, J. F. Thomason, and M. Jackson (2006), Role of transient water pressure in quarrying: a subglacial experiment using acoustic emissions, <i> J. Geophys. Res.</i>, 111, F03006, <a target="_blank" href="https://doi.org/10.1029/2005JF000439">doi.org/10.1029/2005JF000439</a>.</li>
								<li><strong>Cohen D.</strong>, N. R. Iverson, T. S. Hooyer, U. H. Fischer, M.  Jackson, and P. L. Moore (2005), Debris-bed friction of hard-bedded glaciers, <i> J. Geophys. Res.</i>, 110, F02007, <a target="_blank" href="https://doi.org/10.1029/2004JF000228">doi.org/10.1029/2004JF000228</a>.</li>
							</ul>

							<h4>Geomorphology</h4>
							<ul>
								<li>Giadrossich, F., <strong> D. Cohen</strong>, M. Schwarz, A. Ganga, R. Marrosu, M. Pirastru, and G. F. Capra (2019), Large roots dominate the contribution of trees to slope stability, <i>Earth Surf. Process. Landforms</i>, 44, 1602--1609, <a target="_blank" href="https://doi.org/10.1002/esp.4597">doi.org/10.1002/esp.4597</a>.</li>
								<li>Giadrossich, F., M. Schwarz, <strong>D. Cohen</strong>, A. Cislaghi, C.  Vergani, T. Hubble, C. Phillps, and A. Stokes (2017), Methods to measure the mechanical behaviour of tree roots: A review, <i>Ecol. Eng.</i>, 109, 256--271, <a target="_blank" href="https://doi.org/10.1016/j.ecoleng.2017.08.032">doi.org/10.1016/j.ecoleng.2017.08.03</a>.</li>
								<li>Hooyer, T. S., <strong>D. Cohen</strong>, and N. R. Iverson (2012), Control of subglacial quarrying by bedrock joints, <i> Geomorphology</i>, 153--154, 91--101, <a target="_blank" href="https://doi.org/10.1016/j.geomorph.2012.02.012">doi.org/10.1016/j.geomorph.2012.02.012</a>.</li>
								<li><strong>Cohen, D.</strong>, M. Schwarz, and D. Or (2011), An analytical fiber bundle model for pullout mechanics of root bundles, <i>J. Geophys. Res.</i>, 116, F03010, <a target="_blank" href="https://doi.org/10.1029/2010JF001886">doi.org/10.1029/2010JF001886</a>.</li>

							</ul>

							<h4>Hydrogeology</h4>
							<ul>
								<li>Micallef, A., M. Person, A. Haroon, B. A. Weymer, M. Jegen, K. Schwalenberg, Z. Faghih, S. Duan, <strong>D. Cohen</strong>, J. J. Mountjoy, S. Woelz, C. W. Gable, T. Averes, and A. K. Tiwari (2020), 3D characterisation and quantification of an offshore freshened groundwater system in the Canterbury Bight, <i>Nat. Commun.</i>, 11, 1372, <a target="_blank" href="https://doi.org/10.1038/s41467-020-14770-7">doi.org/10.1038/s41467-020-14770-7</a>.</li>
								<li>Zhang, Y., M. Person, V. Voller, <strong>D. Cohen</strong>, J.  McIntosh, and R. Grapenthin (2018), Hydromechanical impacts of Pleistocene glaciations on pore fluid pressure evolution, rock failure, and brine migration within sedimentary basins and the crystalline basement, <i>Water Resour. Res.</i>, 54, <a target="_blank" href="https://doi.org/10.1029/2017WR022464">doi.org/10.1029/2017WR022464</a>.</li>
								<li><strong>Cohen, D.</strong>, M. A. Person, P. Wang, C. W. Gable, D. Hutchinson, A. Marksamer, B. Dugan, H. Kooi, K. Groen, D. Lizarralde, and R. Evans (2010), Origin and extent of fresh paleowaters beneath the Atlantic Continental Shelf, <i> Groundwater</i>, 48, 1, 143--158, <a target="_blank" href="https://doi.org/10.1111/j.1745-6584.2009.00627.x">doi.org/10.1111/j.1745-6584.2009.00627.x</a>.</li>
								<li>Person, M., P. Roy, H. Wright, W. J. Jr. Gutowski, E. Ito, T. Winter, D. Rosenberry, and <strong>D. Cohen</strong> (2007), Hydrologic response of the Crow Wing watershed, Minnesota, to Mid-Holocene climate change, <i> Geol. Soc. Amer. Bull.</i>, 119, 3/4, 363--376, <a target="_blank" href="https://doi.org/10.1130/B26003.1">doi.org/10.1130/B26003.1</a>.</li>
								<li><strong>Cohen D.</strong>, M. A. Person, R. Daannen, S. Locke, D.  Dahlstromn, V. Zabielski, T. C. Winter, D. O. Rosenburry, H. Wright, E. Ito, J. L.  Nieber, and W. J. Jr. Gutowski (2006), Groundwater supported evapotranspiration within glaciated watersheds under conditions of climate change, <i>J. Hydrol.</i>, 320, 484--500, <a target="_blank" href="https://doi.org/10.1016/j.jhydrol.2005.07.051">doi.org/10.1016/j.jhydrol.2005.07.051</a>.</li>
							</ul>

							<h4>Natural Hazards</h4>
							<ul>
								<li>Giadrossich, F., <strong> D. Cohen</strong>, M. Schwarz, A. Ganga, R. Marrosu, M. Pirastru, and G. F. Capra (2019), Large roots dominate the contribution of trees to slope stability, <i>Earth Surf. Process. Landforms</i>, 44, 1602--1609, <a target="_blank" href="https://doi.org/10.1002/esp.4597">doi.org/10.1002/esp.4597</a>.</li>
								<li>Cislaghi, A., <strong>D. Cohen</strong>, E. Gasser, G. B. Bischetti, and M. Schwarz (2019), Field measurements of passive earth forces in steep shallow landslide-prone areas, <i>J. Geophys. Res. Earth Surface</i>, 124, 838--866, <a target="_blank" href="https://doi.org/10.1029/2017JF004557">doi.org/10.1029/2017JF004557</a>.</li>
								<li><strong>Cohen, D.</strong> and M. Schwarz (2017), Tree-root control of shallow landslides, <i>Earth Surf. Dyn.</i>, 5, 451--477, <a target="_blank" href="https://doi.org/10.5194/esurf-5-451-2017">doi.org/10.5194/esurf-5-451-2017</a>.</li>
								<li><strong>Cohen, D.</strong>, M. Schwarz, and D. Or (2011), An analytical fiber bundle model for pullout mechanics of root bundles, <i>J. Geophys. Res.</i>, 116, F03010, <a target="_blank" href="https://doi.org/10.1029/2010JF001886">doi.org/10.1029/2010JF001886</a>.</li>
								<li><strong>Cohen, D.</strong>, P. Lehmann, and D. Or (2009), Fiber bundle model for multiscale modeling of hydromechanical triggering of shallow landslides, <i> Water Resour. Res.</i> 45, W10436,<a target="_blank" href="https://doi.org/10.1029/2009WR007889">doi.org/10.1029/2009WR007889</a>.</li>


							</ul>
						</div>
					</div>
				</div>
			</section>
		</article>
	);
}
