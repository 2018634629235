import React, {useEffect} from 'react';
import './Slideshow.css';

// Banner image imports
import banner0 from '../images/banner0_resized.jpg';
import banner1 from '../images/banner1_resized.jpg';
import banner2 from '../images/banner2_resized.jpg';
import banner3 from '../images/banner3_resized.jpg';
import banner4 from '../images/banner4_resized.jpg';
import banner5 from '../images/banner5_resized.jpg';



const images = [banner0, banner1, banner2, banner3, banner4, banner5];


export default function Slideshow(props) {
	

	function getRndInteger(min, max) {
		return Math.floor(Math.random() * (max - min) ) + min;
	}
	
	const [panel0Background, setPanel0Background] = React.useState(images[0]);
	const [panel1Background, setPanel1Background] = React.useState(images[getRndInteger(0, images.length)]);
	const [currentPanel, setCurrentPanel] = React.useState(0);
	
	useEffect(() => {
		let interval = null;

		interval = setInterval(() => {
			//Randomly select new image
			if (currentPanel === 0 ) {
				setPanel1Background(images[getRndInteger(0, images.length)]);
			} else {
				setPanel0Background(images[getRndInteger(0, images.length)]);
			}
			setCurrentPanel(currentPanel === 0 ? 1 : 0)
		}, 6000);

		return () => clearInterval(interval);
	});

	return (
		<div className="container_row">
			<div className="layer1" style={{ backgroundImage: `url(${panel0Background})`, opacity: currentPanel === 0 ? "1" : "0", transition: "opacity 4s ease"}}>
      	<div className="layerContent">
					{props.children}	
				</div>
    	</div>
			<div className="layer1 layer2" style={{  backgroundImage: `url(${panel1Background})`, opacity: currentPanel === 1 ? "1" : "0", transition: "opacity 4s ease"}}>
      	<div className="layerContent">
					{props.children}	
				</div>
			</div>
		</div>
	);
}