import React from 'react';
import gla02 from '../images/gla02.jpg';
import geomorph02b from '../images/geomorph02b.jpg';
import hydro01b from '../images/hydro01b.jpg';
import nh01 from '../images/nh01.jpg';
import { Link } from 'react-router-dom';
import Slideshow from '../elements/Slideshow';


const headerStyle = {
	marginBottom: 0
};


export default function Home() {

	return (
		<article id="main">
			<Slideshow>
				<h2>CoSci LLC</h2>
				<p><b>Consulting in Earth Sciences</b></p>
			</Slideshow>
			<section className="wrapper style5">
				<div className="inner">
					<div style={{textAlign: "center", backgroundColor: "salmon", borderRadius: "1em", marginTop: "-50px", padding: "0px"}}>
						<p>This site is currently in beta testing. Please report issues to <a href="mailto:webmaster@cosci-llc.com">webmaster@cosci-llc.com</a></p>
					</div>
					<p>We offer expertise in the following areas</p>

					<hr /> 

					<div className="box alt">
						<div className="row gtr-50 gtr-uniform">
							<div className="col-4 col-6-small col-12-xsmall"><Link to="services/glaciology/"><span className="image fit"><h4 style={headerStyle}>Glaciology</h4><img src={gla02} alt="" /></span></Link></div>
							<div className="col-4 col-6-small col-12-xsmall"><Link to="services/geomorphology/"><span className="image fit"><h4 style={headerStyle}> Geomorphology </h4><img src={geomorph02b} alt="" /></span></Link></div>
							<div className="col-4 col-6-small col-12-xsmall"><Link to="services/hydrogeology/"><span className="image fit"><h4 style={headerStyle}> Hydrogeology </h4><img src={hydro01b} alt="" /></span></Link></div>
							<div className="col-4 col-6-small col-12-xsmall"><Link to="services/naturalHazards/"><span className="image fit"><h4 style={headerStyle}> Natural Hazards </h4><img src={nh01} alt="" /></span></Link></div>
						</div>
					</div>
				</div>
			</section>
		</article>
	);
}
