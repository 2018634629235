import React from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import {useParams, Link, Redirect} from 'react-router-dom';
import UserControl from './UserControl';
import Alert from '../../elements/Alert';

const READ_USER = gql`
	query GetUser($id: ID!) {
		currentUser {
			id
			isAdmin
		}
		user(id: $id) {
			id,
			name,
			firstName,
			lastName,
			email,
			isLocked,
			isAdmin
		}
	}
`;

const UPDATE_USER = gql`
	mutation UpdateUser($id: ID!, $first: String!, $last: String!, $email: String!, $password: String, $passwordConfirm: String) {
		updateUser(input: {
			userID: $id,
			firstName: $first,
			lastName: $last,
			email: $email,
			password: $password,
			passwordConfirmation: $passwordConfirm
		}) {
			user {
				name
				id
			}
		}
	}
`;


const LOCK_USER = gql`
	mutation lockUser ($id: ID!) {
		lockUser(input: {
			userID: $id
		}) {
			user {
				id
				isLocked
			}
		}
	}
`;

const UNLOCK_USER = gql`
	mutation unlockUser ($id: ID!) {
		unlockUser(input: {
			userID: $id
		}) {
			user {
				id
				isLocked
			}
		}
	}
`;

export default function ShowUser(props) {
	let { userID } = useParams();
	const [alertText, setAlertText] = React.useState("");
	const { loading, error, data, refetch} = useQuery(READ_USER, {variables: { id: userID }});
	const [updateHook ] = useMutation(UPDATE_USER);
	const [lockHook ] = useMutation(LOCK_USER);
	const [unlockHook ] = useMutation(UNLOCK_USER);
	const [shouldRedirect, setShouldRedirect] = React.useState(false);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :(</p>;
	
	function onAlertClose() {
		setAlertText("");
	}


	function updateUser(userID, first, last, email, password, passwordConfirmation) {
		updateHook({variables: {id: userID, first: first, last:last, email:email, password:password, passwordConfirmation:passwordConfirmation}}).then(result => {
			refetch();
			setShouldRedirect(true);
		}).catch(error => {
			setAlertText(error.message);
		});
	}


	function lockUser(userID) {
		lockHook({variables: {id: userID}}).then(result => {
			refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
	}

	function unlockUser(userID) {
		unlockHook({variables: {id: userID}}).then(result => {
			refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
	}

	if(shouldRedirect) {
		if(data.user.isAdmin) {
			return <Redirect to="/users"/>
		} else {
			return <Redirect to="/portal"/>
		}
	}

	function getHeader() {
		if(data.currentUser.isAdmin) {
			return <h3><Link to="/users">&#8617;</Link>| <Link to="/users">User Management</Link> &rarr; {data.user.name}</h3>;
		} else {
			return <h3><Link to="/users">&#8617;</Link>| <Link to="/portal">Portal</Link> &rarr; {data.user.name} </h3>;
		}
	}

	return (
		<div>
			<Alert show={alertText !== ""} onClick={onAlertClose}>{alertText}</Alert>
			{getHeader()}
			<UserControl 
				data={data.user}
				onLock={data.currentUser.isAdmin ? lockUser : null}
				onUnlock={data.currentUser.isAdmin ? unlockUser : null}
				onSave={updateUser}
				onCancel={() => setShouldRedirect(true)}
			/>
		</div>
	);
}