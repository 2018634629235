import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Link, Redirect} from 'react-router-dom';
import UserControl from './UserControl';
import Alert from '../../elements/Alert';

const ADD_USER = gql`
	mutation AddUser($first: String!, $last: String!, $email: String!, $password: String!) {
		createUser(input: {
			firstName: $first,
			lastName: $last,
			email: $email,
			password: $password,
			passwordConfirmation: $password
		}) {
			user {
				id
				name
			}
		}
	}
`;

export default function AddUser(props) {
	const [createHook ] = useMutation(ADD_USER);
	const [alertText, setAlertText] = React.useState("");
	const [shouldRedirect, setShouldRedirect] = React.useState(false);
	
	function onAlertClose() {
		setAlertText("");
	}
	
	function createUser(first, last, email, password, passwordConfirmation) {
		createHook({variables: {first: first, last:last, email:email, password:password, passwordConfirmation:passwordConfirmation}}).then(result => {
			setShouldRedirect(true);
		}).catch(error => {
			setAlertText(error.message);
		});
	}

	if(shouldRedirect) {
		return <Redirect to="/users"/>
	}


	return (
		<div>
			<Alert show={alertText !== ""} onClick={onAlertClose}>{alertText}</Alert>
			<h3><Link to="/users">&#8617;</Link>| <Link to="/users">User Management</Link> &rarr; Add New User</h3>
			<UserControl 
				onSave={createUser}
				onCancel={() => setShouldRedirect(true)}
			/>
		</div>
	);
}