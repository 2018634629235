import React from 'react';
import './Alert.css';

export default function Alert(props)
{
	const childProps = {...props};
	delete childProps.children;
	delete childProps.show

	if(props.show) {
		return (
			<div className="alert" {...childProps}>
				<span className="closebtn" onClick={props.onClose}>&times;</span>
				{props.children}
			</div>		
		);
	}

	return null;
}