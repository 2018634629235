import React from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import {useParams, useRouteMatch, Switch, Route, Link } from 'react-router-dom';
import TaskList from './Tasks/TaskList';
import TaskDashboard from './Tasks/TaskDashboard';
import Iframe from 'react-iframe'
import AddFileControl from './Tasks/Files/AddFileControl';
import Alert from '../../../elements/Alert';
import Button from '../../../elements/Button';
import Updater from '../../../elements/Updater';
import CRUDList from '../../../elements/CRUDList';
import CRUDGrid from '../../../elements/CRUDGrid';

import PDFIcon from './Tasks/Files/Icons/pdf-5.svg';
import DataIcon from './Tasks/Files/Icons/excel-5.svg';
import PictureIcon from './Tasks/Files/Icons/067-pictures.svg';

const UPDATE_PROJECT = gql`
mutation UpdateProject($companyID: ID!, $projectID: ID!, $name: String!, $description: String!, $timeboardURL: String!) {
	updateProject(input: {
		companyID: $companyID
		projectID: $projectID
		name: $name
		description: $description
		timeboardURL: $timeboardURL
	}) {
		project {
			id
			name
			description
			timeboardURL
		}
	}
}
`;


const UPDATE_FILE = gql`
	mutation UpdateFile($companyID: ID!, $projectID: ID!,  $fileID: ID!, $name: String!) {
		updateFile(input: {
			companyID: $companyID,
			projectID: $projectID,
			fileID: $fileID,
			name: $name
		}) {
			file {
				id
				name
			}
		}
	}
`;

const DELETE_FILE = gql`
	mutation DeleteFile($companyID: ID!, $projectID: ID!, $fileID: ID!) {
		deleteFile(input: {
			companyID: $companyID,
			projectID: $projectID,
			fileID: $fileID
		}) {
			success,
			errors
		}
	}
`;

export default function ProjectDashboard(props) {
	let { companyID, projectID } = useParams();
	let path = useRouteMatch().path;

	const GET_PROJECT = gql`
		query GetProject {
			fileCategories {
				id
				name
			}
			currentUser {
				isAdmin
				company (id: ${companyID}) {
					name
					project (id: ${projectID}) {
						id
						name
						description
						timeboardURL
						files {
							id
							name
							fileCategory {
								name
							}
						}
					}
				}
			}
		}
	`;



	const { loading, error, data, refetch } = useQuery(GET_PROJECT);
	const [showAddNew, setShowAddNew] = React.useState(false);
	const [updateHook] = useMutation(UPDATE_PROJECT);
	const [updateFileHook ] = useMutation(UPDATE_FILE);
	const [deleteFileHook ] = useMutation(DELETE_FILE);
	const [alertText, setAlertText] = React.useState("");
	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :-(</p>;

	function onTimeboardUpdate(newValue) {
		updateHook({variables: {companyID: companyID, 
			projectID: projectID, 
			name: data.currentUser.company.project.name, 
			description: data.currentUser.company.project.description, 
			timeboardURL: newValue
				}}).then(result => {
		refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
	}

	function updateFile(fileID, fileName) {
		updateFileHook({variables: {companyID: companyID, 
														projectID: projectID, 
														fileID: fileID,
														name: fileName
													}}).then(result => {
			refetch();
		}).catch(error => {
			alert(error);
		});
	}

	function deleteFile(fileID) {
		deleteFileHook({variables: {companyID: companyID, 
														projectID: projectID,
														fileID: fileID		
													}}).then(result => {
			refetch();
		}).catch(error => {
			alert(error);
		});
	}

	function getAdminTimeboardEditor(timeboard) {
		if(data.currentUser.isAdmin) {
			//Show edit link
			return (
				<div>
					<Updater update={onTimeboardUpdate} field="Timeboard URL" text={data.currentUser.company.project.timeboardURL}/>
					<hr/>
				</div>
			);
		}
	}

	function getTimeboard() {
		if(data.currentUser.company.project.timeboardURL !== "") {
			return (
				<div>
					<h4>GitKraken Timeline</h4>

					<Iframe url={data.currentUser.company.project.timeboardURL}
									width="100%"
									height="500px"
									/>
					<a href={data.currentUser.company.project.timeboardURL}>Click here to open timeline in seperate tab</a>
					{ getAdminTimeboardEditor(true)}
					
				</div>
			);
		}
		else {
			return getAdminTimeboardEditor(false);
		}
	} 
	function onAlertClose() {
		setAlertText("");
	}

	function getDownloadFile(fileID) {
		let client = localStorage.getItem("client");
		let token = localStorage.getItem("accessToken");
		let uid = localStorage.getItem("uid");
		
		return `/download/${companyID}/${projectID}/${fileID}?access-token=${token}&uid=${uid}&client=${client}`;
	}

	function addObjectElements() {
		if(showAddNew) {

			// Find the file category ID
			const fc = data.fileCategories.filter(
				fc => fc.name === "Data" || fc.name === "Reports" || fc.name === "Presentations"
			)

			return (
				<AddFileControl
					companyID={companyID}
					projectID={projectID}
					taskID={null}
					onAdd={() => { setShowAddNew(false); refetch(); }}
					fileCategories={fc}
				/>
			);
		} else {
			if(!data.currentUser.isAdmin) {
				return;
			}
			return <Button style={{ marginTop: "10px"}} onClick={() => setShowAddNew(true)}>Add New File</Button>
		}
	}

	function getIcon(entry) {
		switch(entry.fileCategory.name) {
			case 'Reports':
			case 'Presentations':
				return PDFIcon;
			
			case "Results":
			case 'Data':
				return DataIcon;

			case "Images":
				return PictureIcon;
		
			default:
				return null;
		}
	}

	function getFiles(category) {
		
		const reports = data.currentUser.company.project.files.filter(
			file => file.fileCategory.name === category
		)

		if (reports.length === 0) {
			return <p>No files have been uploaded</p>
		}

		return (

			<CRUDGrid
				isAdmin={data.currentUser.isAdmin}
				title=""
				data={reports}
				name={(file) => {return file.name}}
				id={(file) => { return file.id}}
				entry={(id, name) => {return <Link download target="_blank" to={getDownloadFile(id)}>{name}</Link>}}
				showCreateEntry={false}
				cancelCreateEntry={null}
				createEntry={null}
				updateEntry={updateFile}
				deleteEntry={deleteFile}
				icon={getIcon}
			/>
	);
	}

	return (
		<Switch>
			<Route path={`${path}/tasks/:taskID`}>
				<TaskDashboard/>
			</Route>
			<Route path={`${path}`}>
				<div>
					<Alert show={alertText !== ""} onClick={onAlertClose}>{alertText}</Alert>
					
					<i class="fa fa-home" aria-hidden="true"></i> <Link to="/portal">Portal Home</Link> <i class="fa fa-chevron-right" aria-hidden="true"></i> <Link to={`/portal/${companyID}`}>{data.currentUser.company.name}</Link> 
					<h2>{data.currentUser.company.project.name}</h2>
					<p>{data.currentUser.company.project.description}</p>
					{getTimeboard()}

					<h4>Reports</h4>
					{ getFiles("Reports") }

					<hr/>

					<h4>Presentations</h4>
					{ getFiles("Presentations") }

					<hr/>

					<h4>Data Files</h4>
					{ getFiles("Data") }
					<hr/>
					{ addObjectElements() }
					<hr/>
					<TaskList/>
				</div>
			</Route>
	</Switch>	
	);
}
