import React, { useState } from 'react';
import Button from './Button';
import TextBox from './Textbox';


export default function Updater(props) {
	const [text, SetText] = useState(props.text);
	const [isEditing, setIsEditing] = useState(text === "");


	function handleOnChange(event) {
		SetText(event.target.value);
	}

	function handleOnClick() {
		props.update(text);
		setIsEditing(false);
	}

	if(isEditing || text === "") {
		return (
			<div>
				<TextBox onChange={handleOnChange} value={text} placeholder={props.field} type="text"/>
				<br/>
				<Button onClick={handleOnClick}>{props.text === "" ? "Add " + props.field : "Save"}</Button>
			</div>
		);
	} else {
		return (
			<div>
				<p>{text}</p>
				<Button onClick={() => setIsEditing(true)}>Edit</Button>
			</div>
		);
	}
		
}