import React from 'react';
import Textbox from './Textbox';
import Button from './Button';


export default function CRUDList(props) {
	const [editID, setEditID] = React.useState(false);
	const [editName, setEditName] = React.useState("");
	const [deleteID, setDeleteID] = React.useState(false);

	function handleEditTextboxOnChange(e) {
		setEditName(e.target.value);
	}

	function editObjectElements(id, name) {
		if (id === editID) { // Saving the entry
			props.updateEntry(id,editName);
			setEditID(false);
			setEditName("");
		} else {
			setDeleteID(false);
			setEditName(name);
			setEditID(id);
		}
	}
	
	function deleteObject(id) {
		if(id === deleteID) { // They are sure. Delete the entry
			props.deleteEntry(id);
		} else {
			setDeleteID(id);
		}
	}

	function getListItem(id,  name) {
		if(id === editID) {
			return (
				<Textbox onChange={handleEditTextboxOnChange} value={editName} type="text" name="editBox" />
			);
		} else {
			return props.entry(id, name);
		};
	}	

	function getEditButton(id, name) {
		if(props.updateEntry && props.isAdmin) {
			return <td><Button secondary={!editID || id !== editID} onClick={() => editObjectElements(id, name)}>{editID === id ? "Save" : "Edit"}</Button></td>
		}
	}

	function getDeleteButton(id) {
		if(props.deleteEntry  && props.isAdmin) {
			return <td><Button secondary={!deleteID || id !== deleteID} onClick={() => deleteObject(id)}>{deleteID === id ? "Are you sure?" : "Delete"}</Button></td>
		}
	}

	return (
		<div className="table-wrapper">
			<table>
				<thead>
					<tr>
						<th>{props.title}</th>
					</tr>
				</thead>
				<tbody>
					{
						props.data.map((entry) => {
							const id = props.id(entry);
							const name = props.name(entry);
							return (
								<tr key={id}>
									<td>
										{getListItem(id, name)}
									</td>
									{ getEditButton(id, name) }
									{ getDeleteButton(id) }
								</tr>
							);
						})
					}
					{ props.showCreateEntry && 
						<tr key="brandNewID">
						<td>
						<Textbox onChange={handleEditTextboxOnChange} value={editName} type="text" name="editBox" />
						</td>
						<td><Button onClick={() => props.createEntry(editName)}>Save</Button></td>
						<td><Button secondary={true} onClick={props.cancelCreateEntry}>Cancel</Button></td>
					</tr>
					}
				</tbody>
			</table>
		</div>
	);
}