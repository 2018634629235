import React, { useState } from 'react';
import Textbox from '../elements/Textbox';
import Button from '../elements/Button';
import Slideshow from '../elements/Slideshow';

export default function Contact() {
	const [contactRequest, setContactRequest] = useState({
		firstName: "",
		lastName: "",
		email: "",
		message: ""
	});
	const [showError, setShowError] = useState("");
	const [showSuccess, setShowSuccess] = useState("");

	function handleTextBoxOnChange(event) {
		setContactRequest({ ...contactRequest, [event.target.name]: event.target.value});
	}

	function ValidateEmail(email) 
	{
		if (/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
		{
			return (true)
		}
		return (false)
	}

	function onSubmit() {
		setShowSuccess("");
		setShowError("");
		if(contactRequest.firstName === "") {
			setShowError("First name must be filled");
			return;
		}
		if(contactRequest.lastName === "") {
			setShowError("Last name must be filled");
			return;
		}
		if(contactRequest.email === "") {
			setShowError("Email must be filled");
			return;
		}
		if(contactRequest.message === "") {
			setShowError("Message must be filled");
			return;
		}
		if(!ValidateEmail(contactRequest.email)) {
			setShowError("Email address is incorrect");
			return;
		}

		const data = new URLSearchParams();
		data.append("email", contactRequest.email);
		data.append("name", contactRequest.firstName + " " + contactRequest.lastName);
		data.append("body", btoa(contactRequest.message));
		
		fetch("/sendEmail", 
					{ method: 'POST', 
						body: data
					})
		.then(result => {
			setShowSuccess("Message Sent!")
		}).catch(error => {
			setShowError(error.message);
		});
	}

	return (
		<article id="main">
			<Slideshow>
				<h2>Contact</h2>
			</Slideshow>
			<section class="wrapper style5">
				<div class="inner">
				<div style={{textAlign: "center", backgroundColor: "salmon", borderRadius: "1em", marginTop: "-50px", padding: "0px"}}>
						<p>This site is currently in beta testing. Please report issues to <a href="mailto:webmaster@cosci-llc.com">webmaster@cosci-llc.com</a></p>
					</div>
					<p>Please fill the form to contact us. We will get back to you as soon as possible.</p>
					
					<hr/>
					<h4>Name</h4>
					<div className="row gtr-uniform">
						<div className="col-6 col-12-xsmall">
							<Textbox onChange={handleTextBoxOnChange} value={contactRequest.firstName} name="firstName" type="text" placeholder="First"/>
						</div>
						<div className="col-6 col-12-xsmall">
							<Textbox onChange={handleTextBoxOnChange} value={contactRequest.lastName} name="lastName"  type="text" placeholder="Last"/>
						</div>
					</div>
					<br/>

					<h4>Email</h4>
					<Textbox onChange={handleTextBoxOnChange} value={contactRequest.email} name="email" type="text" placeholder="Email"/>
					<br/>

					<h4>Message</h4>
					<textarea onChange={handleTextBoxOnChange} value={contactRequest.message} name="message" rows="6" placeholder="Enter your message"/>

					<br/>
					<Button onClick={onSubmit}>
						Submit
					</Button>
					{showError !== "" && <p style={{color: "red"}}>{showError}</p>}
					{showSuccess !== "" && <p style={{color: "green"}}>{showSuccess}</p>}
					<hr />
				</div>
			</section>
		</article>
	);
}