import React from 'react';



export default function Combobox(props) {

	return (
		<div>
			<label htmlFor={"combo" + props.title}>{props.title}</label>
			<select id={"combo" + props.title} value={props.selectedItem} onChange={props.onSelect}>
				<option key={"blank" + props.title} value="">- Select an option -</option>
				{
				props.data.map((element) => {
					const name = props.name(element)
					const id = props.id(element)
					return (<option key={id} value={id}>{name}</option>);
				})
				}
			</select>
		</div>
		
	)
}