import React from 'react';
import Collapsible from 'react-collapsible';
import './CollapsibleList.css'

export default function CollapsibleList(props) {
	return (
		<div>
			<Collapsible trigger={props.title} open={true}>
				{props.children}
			</Collapsible>
		</div>
	);

}