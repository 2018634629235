import React from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import Button from '../../../../elements/Button';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import CRUDList from '../../../../elements/CRUDList';
import Alert from '../../../../elements/Alert';

const LIST_TASKS = gql`
	query ListProjects($company: ID!, $project: ID!) {
		currentUser {
			id
			isAdmin
			company(id: $company) {
				id
				project(id: $project) {
					id
					name 
					tasks {
						id
						name
					}
				}
			}
		}
	}
`;

const ADD_TASK = gql`
	mutation AddTask($companyID: ID!, $name: String!, $projectID: ID!) {
		createTask(input: {
			companyID: $companyID
			projectID: $projectID
			name: $name,
		}) {
			task {
				id
				name
			}
		}
	}
`;

const UPDATE_TASK = gql`
	mutation updateTask($companyID: ID!, $projectID: ID!, $taskID: ID!, $name: String!) {
		updateTask(input: {
			companyID: $companyID,
			projectID: $projectID,
			taskID: $taskID
			name: $name,
		}) {
			task {
				id
				name
			}
		}
	}
`;

const DELETE_TASK = gql`
	mutation deleteTask($companyID: ID!, $projectID: ID!,$taskID: ID!) {
		deleteTask(input: {
			companyID: $companyID,
			projectID: $projectID,
			taskID: $taskID
		}) {
			success,
			errors
		}
	}
`;



export default function TaskList() {
	let { companyID, projectID } = useParams();
	let url = useRouteMatch().url;
	const [alertText, setAlertText] = React.useState("");
	const { loading, error, data, refetch } = useQuery(LIST_TASKS, {variables: {company: companyID, project: projectID}});
	const [showAddNew, setShowAddNew] = React.useState(false);
	const [createHook ] = useMutation(ADD_TASK);
	const [updateHook ] = useMutation(UPDATE_TASK);
	const [deleteHook ] = useMutation(DELETE_TASK);
	

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :(</p>;
	
	function createTask(taskName) {
		createHook({variables: { companyID: companyID, projectID: projectID, name: taskName}}).then(result => {
			refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
		setShowAddNew(false);
	}

	function updateTask(taskID, taskName) {
		updateHook({variables: {companyID: companyID, projectID: projectID, taskID: taskID, name: taskName}}).then(result => {
			refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
	}

	function deleteTask(taskID) {
		deleteHook({variables: {companyID: companyID, projectID: projectID, taskID: taskID}}).then(result => {
			refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
	}

	function onAlertClose() {
		setAlertText("");
	}

	return (
		<div>
			<h4>Tasks</h4>
			<Alert show={alertText !== ""} onClick={onAlertClose}>{alertText}</Alert>
			<CRUDList
				isAdmin={data.currentUser.isAdmin}
				title=""
				data={data.currentUser.company.project.tasks}
				name={(task) => {return task.name}}
				id={(task) => { return task.id}}
				entry={(id, name) => {return <Link to={`${url}/tasks/${id}`}>{name}</Link>}}
				showCreateEntry={showAddNew}
				cancelCreateEntry={() => setShowAddNew(false)}
				createEntry={createTask}
				updateEntry={updateTask}
				deleteEntry={deleteTask}
			/>
			{data.currentUser.isAdmin && <Button onClick={() => setShowAddNew(true)}>Add New Task</Button> }
		</div>
	);
}
