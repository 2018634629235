import React from 'react';
import TextBox from '../elements/Textbox';
import {gql, ApolloClient, InMemoryCache } from '@apollo/client';
import {Redirect} from 'react-router-dom';
import Alert from '../elements/Alert';
import Slideshow from '../elements/Slideshow';

export default function Login(props) {

	const [username, setUsername] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [success, setSuccess] = React.useState(false);
	const [alertText, setAlertText] = React.useState("");

	function signIn(e) {
		e.preventDefault();
		
		const client = new ApolloClient({
			uri: '/graphql',
			cache: new InMemoryCache()
		});
		client.mutate({
			mutation: gql`
				mutation Login{
					userLogin(email: "${username}", 
										password:"${password}") 
					{
						credentials {
							accessToken
							uid
							client
							expiry
						}
					}
				}
			`
		}).then(result => {
			let client = result.data.userLogin.credentials.client;
			localStorage.setItem("client", client);

			let accessToken = result.data.userLogin.credentials.accessToken;
			localStorage.setItem("accessToken", accessToken);

			let uid = result.data.userLogin.credentials.uid;
			localStorage.setItem("uid", uid);

			localStorage.setItem("expiry", result.data.userLogin.credentials.expiry);
			props.onSuccessCallback(accessToken, client, uid);
			setSuccess(true);
		})
			.catch(error => setAlertText("Invalid Credentials"));
	}

	function handleOnChange(textbox) {
		switch(textbox.target.name) {
			case "username":
				setUsername(textbox.target.value);
				break;
			case "password":
				setPassword(textbox.target.value);
				break;
			default:
				console.log("Incorect option passed to handleOnChange");
				break;
		}
	}

	if(success) {
    return <Redirect to="/portal" />;
	}
	
	function onAlertClose() {
    setAlertText("");
	}
	

	return (
		<article id="main">
			<Alert show={alertText !== ""} onClick={onAlertClose}>{alertText}</Alert>
			<Slideshow>
				<h2>Login</h2>
			</Slideshow>
			<section className="wrapper style5">
				<div className="inner">
				<div style={{textAlign: "center", backgroundColor: "salmon", borderRadius: "1em", marginTop: "-50px", padding: "0px"}}>
						<p>This site is currently in beta testing. Please report issues to <a href="mailto:webmaster@cosci-llc.com">webmaster@cosci-llc.com</a></p>
					</div>
					<h3>Client Login</h3>
					<p>Please use the username and password you have received in your email to log into the secure area.</p>
					<form onSubmit={signIn} action="#" >
						<div className="row">
							<div className="col-2 col3-small"></div>
							<div className="col-8 col6-small col-12-xsmall">
								<label htmlFor="username">Username:</label>
								<TextBox onChange={handleOnChange} type="text" name="username" id="username" placeholder="Username" />
								<br/>
								<label htmlFor="password">Password:</label>
								<TextBox onChange={handleOnChange} type="password" name="password" id="password" placeholder="Password" />
								<br/>
								<input type="submit" className="button primary" value="Login"/>
							</div>
							<div className="col-2 col3-small"></div>
						</div>
					</form>
				</div>
			</section>
			
		</article>
	
	);
}