import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './images/logo.png';

export default function Header(props) {
	return (
		<div id="header">
			<Link to="/"><img style={{height: "44px", marginTop: "5px", marginLeft: "5px"}} src={Logo} alt="CoSci LLC"/></Link>
			<nav id="nav">
				<ul>
					<li className="special">
						<a onClick={props.onMenuClick} className="menuToggle"><span>Menu</span></a>
					</li>
				</ul>
			</nav>
		</div>
	);
}