import React from 'react';
import { useQuery, gql } from '@apollo/client';
import {useParams, useRouteMatch, Switch, Route, Link} from 'react-router-dom';
import FileList from './Files/FileList';

export default function TaskDashboard(props) {
	let { companyID, projectID, taskID } = useParams();
	let { path } = useRouteMatch();

	const GET_PROJECT = gql`
		query GetTask {
			company (id: ${companyID}) {
				name
				project (id: ${projectID}) {
					name
					task (id: ${taskID}) {
						id
						name
						description
					}
				}
			}
		}
	`;

	const { loading, error, data } = useQuery(GET_PROJECT);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :(</p>;

	return (
		<Switch>
			<Route path={`${path}`}>
				<div>
					<i class="fa fa-home" aria-hidden="true"></i> <Link to="/portal">Portal Home</Link> <i class="fa fa-chevron-right" aria-hidden="true"></i> <Link to={`/portal/${companyID}`}>{data.company.name}</Link> <i class="fa fa-chevron-right" aria-hidden="true"></i>  <Link to={`/portal/${companyID}/projects/${projectID}`}>{data.company.project.name}</Link>
					<h2>{data.company.project.task.name}</h2>
					<p>{data.company.project.task.description}</p>
					<h4>File List</h4>	
					<FileList/>
				</div>
			</Route>
	</Switch>	
	);
}