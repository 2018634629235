import React, {useState} from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import CRUDList from '../../elements/CRUDList';
import Button from '../../elements/Button';
import { Link, useRouteMatch, Redirect } from 'react-router-dom';
import Combobox from '../../elements/Combobox';


const LIST_USERS = gql`
	query ListUsers {
		currentUser {
			isAdmin
		}
		users {
			id,
			name,
			isLocked,
			email
		}
	}
`;

const SEND_PASSWORD_RESET = gql`
	mutation SendEmail($account: String!) {
		userSendPasswordReset(email: $account, redirectUrl: "cosci-llc.com")
		{	
			message
		}
	}
`;


export default function ListUsers(props) {
	const { loading, error, data} = useQuery(LIST_USERS);
	const [resetPassword ] = useMutation(SEND_PASSWORD_RESET);
	let  url = useRouteMatch().url;
	const	[ shouldRedirect, setShouldRedirect] = useState(false);
	const [infoMessage, setInfoMessage] = React.useState("");
	const [selectedID, setSelectedID] = React.useState("");
	
	if(shouldRedirect) return <Redirect to={`${url}/new`}/>
	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :(</p>;


	function handleComboboxSelect(event) {
		setSelectedID(event.target.value);
	}
	
	function onPasswordReset() {



		resetPassword({variables: {account: selectedID}}).then(result => {
			setInfoMessage("Email has been sent!");
		}).catch(error => {
			setInfoMessage(error.message);
		});
	}

	return (
		<div>
			<h4>Users</h4>
			<CRUDList
				isAdmin={data.currentUser.isAdmin}
				title="Names"
				data={data.users}
				name={(user) => {return user.name + (user.isLocked ? " (LOCKED)" : "") }}
				id={(user) => { return user.id}}
				entry={(id, name) => {return (<Link to={`${url}/${id}`}>{name}</Link>)}}
				showCreateEntry={false}
				cancelCreateEntry={false}
				createEntry={false}
				updateEntry={false}
				deleteEntry={false}
			/>
			<Button onClick={() => setShouldRedirect(true)}>Add New User</Button>

			<hr/>
			<h3>Password Resets</h3>
			<Combobox 
				name={(user) => {return `${user.name} (${user.email})`}}
				id={(user) => { return user.email}}
				data={data.users}
				onSelect={handleComboboxSelect}
				selectedItem={selectedID === null ? "" : selectedID}
				title="Send reset email to:"
			/>
			<br/>
			<Button onClick={onPasswordReset}>Send Password Reset</Button>
			{ infoMessage !== "" && <p>{infoMessage}</p>}
		</div>
	);
}