import React from 'react';
import AddUser from './AddUser';
import ListUser from './ListUsers';
import {Link, Route, Switch, useRouteMatch} from 'react-router-dom';
import ShowUser from './ShowUser';
import Slideshow from '../../elements/Slideshow';





export default function UserManagement() {
	let { path } = useRouteMatch();
	return (
		<article id="main">
			<Slideshow>
				<h2>User Managment</h2>
			</Slideshow>
			<section className="wrapper style5">
				<div className="inner">
					<Switch>
					<Route path={`${path}/new`}>
							<AddUser/>
						</Route>
						<Route path={`${path}/:userID`}>
							<ShowUser/>
						</Route>
						<Route path={`${path}`}>
							<h3><Link to="/portal">&#8617;</Link>| User Management</h3>
							<ListUser/>
						</Route>
					</Switch>	
				</div>
			</section>
		</article>
	);
}