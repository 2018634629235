import React from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import {Link} from 'react-router-dom';
import CRUDList from '../../elements/CRUDList';
import Button from '../../elements/Button';
import Alert from '../../elements/Alert';
import Combobox from '../../elements/Combobox';

const LIST_USERS = gql`
query GetUsersFromCompany($companyID: ID!) {
	currentUser {
		isAdmin
		company (id: $companyID) {
			name
			users {
				id
				name
			}
			unassociatedUsers {
				id
				name
			}
		}
	}
}
`;


const ASSOCIATE_USER=gql`
mutation Associations($companyID: ID!, $userID: ID!) {
  associateUserToCompany(input: {
    userId: $userID,
    companyId: $companyID
  }) {
    user {
			id
      name
    }
  }
}
`;

const UNASSOCIATE_USER=gql`
mutation Unassociation($companyID: ID!, $userID: ID!) {
  unassociateUserFromCompany(input: {
    userId: $userID,
    companyId: $companyID
  }) {
    user {
			id
      name
    }
  }
}
`;


export default function UserAssociationList(props) {
	const [selectedID, setSelectedID] = React.useState("");
	const { loading, error, data, refetch } = useQuery(LIST_USERS, { variables: {companyID: props.companyID }});
	const [updateHook ] = useMutation(ASSOCIATE_USER);
	const [deleteHook ] = useMutation(UNASSOCIATE_USER);
	const [alertText, setAlertText] = React.useState("");

	if (loading) return <p>Loading...</p>;
	if(error) return <p>Error :-(</p>;

	function unassociateUser(userID) {
		deleteHook({variables: {
			companyID: props.companyID, 
			userID: userID
		}}).then(result => {
			refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
	}

	function associateUser() {
		updateHook({variables: {
			companyID: props.companyID, 
			userID: selectedID
		}}).then(result => {
			refetch();
			setSelectedID(null);
		}).catch(error => {
			setAlertText(error.message);
		});
	}

	function handleComboboxSelect(event) {
		setSelectedID(event.target.value);
	}

	function onAlertClose() {
		setAlertText("");
	}

	return (
		<div>
			<Alert show={alertText !== ""} onClick={onAlertClose}>{alertText}</Alert>
			<CRUDList
				isAdmin={data.currentUser.isAdmin}
				title=""
				data={data.currentUser.company.users}
				name={(user) => {return user.name}}
				id={(user) => { return user.id}}
				entry={(id, name) => {return <Link to={`/users/${id}`}>{name}</Link>}}
				showCreateEntry={false}
				createEntry={false}
				updateEntry={false}
				deleteEntry={unassociateUser}
			/>
			<Combobox 
				name={(user) => {return user.name}}
				id={(user) => { return user.id}}
				data={data.currentUser.company.unassociatedUsers}
				onSelect={handleComboboxSelect}
				selectedItem={selectedID === null ? "" : selectedID}
				title="Unassociated Users To Add:"
			/>
			<Button onClick={associateUser}>Associate User</Button>
		</div>
	);
}