import React from 'react';

export default function Button(props)
{
	const childProps = {...props};
	delete childProps.children;

	if(props.className === undefined) {
		childProps.className = "button primary";
	}

	if(props.secondary !== undefined && props.secondary !== false) {
		childProps.className = "button secondary";	
	}
	delete childProps.secondary

	return (
		<a {...childProps} >{props.children}</a>
	);
}