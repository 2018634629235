import React from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import Button from '../../../elements/Button';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import CRUDList from '../../../elements/CRUDList';
import Alert from '../../../elements/Alert';

const LIST_PROJECTS = gql`
	query ListProjects($company: ID!) {
		currentUser {
			id
			isAdmin
			company(id: $company) {
				projects{
					id
					name
				}
			}
		}
	}
`;

const ADD_PROJECT = gql`
	mutation AddProject($companyID: ID!, $name: String!, $desc: String!) {
		createProject(input: {
			companyID: $companyID
			name: $name,
			description: $desc
		}) {
			project {
				id
				name
			}
		}
	}
`;


const UPDATE_PROJECT = gql`
	mutation UpdateProject($companyID: ID!, $projectID: ID!, $name: String!) {
		updateProject(input: {
			companyID: $companyID,
			projectID: $projectID
			name: $name,
		}) {
			project {
				id
				name
			}
		}
	}
`;

const DELETE_PROJECT = gql`
	mutation DeleteProject($companyID: ID!, $projectID: ID!) {
		deleteProject(input: {
			companyID: $companyID,
			projectID: $projectID
		}) {
			success,
			errors
		}
	}
`;


export default function ProjectList() {
	let { companyID } = useParams();
	let url = useRouteMatch().url;
	const [alertText, setAlertText] = React.useState("");
	const { loading, error, data, refetch } = useQuery(LIST_PROJECTS, {variables: {company: companyID}});
	const [showAddNew, setShowAddNew] = React.useState(false);
	const [createHook ] = useMutation(ADD_PROJECT);
	const [updateHook ] = useMutation(UPDATE_PROJECT);
	const [deleteHook ] = useMutation(DELETE_PROJECT);
	

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :(</p>;
	
	function createProject(projectName) {
		createHook({variables: {companyID: companyID, name: projectName, desc: ""}}).then(result => {
			refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
		setShowAddNew(false);
	}

	function updateProject(projectID, projectName) {
		updateHook({variables: {companyID: companyID, projectID: projectID, name: projectName}}).then(result => {
			refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
	}

	function deleteProject(projectID) {
		deleteHook({variables: {companyID: companyID, projectID: projectID}}).then(result => {
			refetch();
		}).catch(error => {
			setAlertText(error.message);
		});
	}

	function onAlertClose() {
		setAlertText("");
	}

	return (
		<div>
			<Alert show={alertText !== ""} onClick={onAlertClose}>{alertText}</Alert>
			<h4>Projects</h4>
			<CRUDList
				isAdmin={data.currentUser.isAdmin}
				title=""
				data={data.currentUser.company.projects}
				name={(project) => {return project.name}}
				id={(project) => { return project.id}}
				entry={(id, name) => {return <Link to={`${url}/projects/${id}`}>{name}</Link>}}
				showCreateEntry={showAddNew}
				cancelCreateEntry={() => setShowAddNew(false)}
				createEntry={createProject}
				updateEntry={updateProject}
				deleteEntry={deleteProject}
			/>
			{data.currentUser.isAdmin && <Button onClick={() => setShowAddNew(true)}>Add New Project</Button>}
			
		</div>
	);
}
