import React from 'react';
import {Link} from 'react-router-dom';

export default function Footer() {
	return (
		<footer id="footer"  style={{flexShrink: "0" }}>
			<ul className="icons">
				<li><Link to="/contact" className="icon solid fa-envelope"><span className="label">Email</span></Link></li>
				<li><a href="https://github.com/Cosci-LLC" className="icon brands fa-github"><span className="label">GitHub</span></a></li>
			</ul>
			<ul className="copyright">
				<li>&copy; CoSci LLC</li>
				<li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
			</ul>
			<ul className="copyright">
			Icons made by <a href="https://www.flaticon.com/authors/pixel-buddha" title="Pixel Buddha">Pixel Buddha</a> and <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a>from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
			</ul>
		</footer>
	);
}
