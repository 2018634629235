import React from 'react';
import { useQuery, gql } from '@apollo/client';
import ProjectList from './Projects/ProjectList';
import ProjectDashboard from './Projects/ProjectDashboard';
import {useParams, Switch, Route, useRouteMatch, Link} from 'react-router-dom';
import UserAssociationList from './UserAssociationList';



export default function CompanyDashboard(props) {
	const path = useRouteMatch().path;
	let { companyID } = useParams();

	const LIST_COMPANY = gql`
		query ListCompany {
			currentUser {
				company (id: ${companyID}) {
					id
					name
				}
				id
				isAdmin
			}
		
		}
	`;

	const { loading, error, data } = useQuery(LIST_COMPANY);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :(</p>;

	

	function assocatedUsersControl() {
		if(data.currentUser.isAdmin) {		
			return (
				<div>
					<h3>Associated Users</h3>
					<UserAssociationList companyID={companyID}/>
				</div>
			);
		}
		return null;
	}

	return (
		<Switch>
			<Route path={`${path}/projects/:projectID` }>
				<ProjectDashboard/>
			</Route>
			<Route path={`${path}`}>
				<i class="fa fa-home" aria-hidden="true"></i> <Link to="/portal">Portal Home</Link>
				<h2>{data.currentUser.company.name}</h2>
				<ProjectList/>
				{assocatedUsersControl()}
			</Route>
	</Switch>	
	);
}