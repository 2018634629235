import React from 'react';
import { gql, useMutation } from '@apollo/client';
import Textbox from '../../../../../elements/Textbox';
import Button from '../../../../../elements/Button';
import Combobox from '../../../../../elements/Combobox';


const ADD_FILE = gql`
	mutation AddFile($companyID: ID!, $projectID: ID!, $taskID: ID!, $fileCategoryID: ID!, $name: String!, $fileLocation: String!) {
		createFile(input: {
			companyID: $companyID
			projectID: $projectID
			taskID: $taskID
			name: $name,
			fileLocation: $fileLocation,
			fileCategoryID: $fileCategoryID
		}) {
			file {
				id
				name
			}
		}
	}
`;

const ADD_FILE_NO_TASK_ID = gql`
	mutation AddFile($companyID: ID!, $projectID: ID!, $fileCategoryID: ID!, $name: String!, $fileLocation: String!) {
		createFile(input: {
			companyID: $companyID
			projectID: $projectID
			name: $name,
			fileLocation: $fileLocation,
			fileCategoryID: $fileCategoryID
		}) {
			file {
				id
				name
			}
		}
	}
`;

export default function AddFileControl(props) {
	const [newfileName, setnewfileName] = React.useState("");
	const [fileLocation, setFileLocation] = React.useState("");
	const [addFile ] = useMutation(props.taskID === null ? ADD_FILE_NO_TASK_ID : ADD_FILE);
	const [fcID, setFCID] = React.useState("");

	const companyID = props.companyID;
	const projectID = props.projectID;
	const taskID = props.taskID;

	function handleTextboxOnChange(e) {
		setnewfileName(e.target.value);
	}

	function handleTextboxLocationOnChange(e) {
		setFileLocation(e.target.value);
	}

	function handleComboboxSelect(event) {
		setFCID(event.target.value);
	}

	function handleButtonOnClick() {
		if(fileLocation.includes("/projects/website/")) {
			var newFileLocation = fileLocation.substring(18);
		} else {
			var newFileLocation = fileLocation;
		}
		addFile({variables: {
													name: newfileName, 
													fileLocation: newFileLocation, 
													companyID: companyID, 
													projectID: projectID, 
													taskID: taskID,
													fileCategoryID: fcID
												}}).then(result => {
			props.onAdd();
		}).catch(error => {
			
		});
		setnewfileName("");
		setFileLocation("");
	}

	return (
		<div style={{ marginTop: "10px"}}>
			<hr/>
			<Textbox value={newfileName} onChange={handleTextboxOnChange} type="text" placeholder="File Name" />
			<br/>
			<Textbox value={fileLocation} onChange={handleTextboxLocationOnChange} type="text" placeholder="Full File Path (including file name)" />
			<Combobox
				id={(fc) => { return fc.id }}
				name={(fc) => { return fc.name}}
				data={props.fileCategories}
				onSelect={handleComboboxSelect}
				selectedItem={fcID}
			/>
			<br/>
			<Button onClick={handleButtonOnClick}>Add File</Button>
		</div>
	);
}

