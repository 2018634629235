import React, { useState, useEffect } from 'react';
import TextBox from '../../elements/Textbox';
import Button from '../../elements/Button';


export default function UserControl(props) {
	const [userData, setUserData] = useState({
		firstName: props.data === undefined ? "" : props.data.firstName,	
		lastName: props.data === undefined ? "" : props.data.lastName,
		email: props.data === undefined ? "" : props.data.email,
		isLocked: props.data === undefined ? "" : props.data.isLocked
	});

	useEffect(() => {
		setUserData({
			firstName: props.data === undefined ? "" : props.data.firstName,	
			lastName: props.data === undefined ? "" : props.data.lastName,
			email: props.data === undefined ? "" : props.data.email,
			isLocked: props.data === undefined ? "" : props.data.isLocked
		});
	}, [props.data]);
	
	function handleTextBoxOnChange(event) {
		setUserData({...userData, [event.target.name]: event.target.value});
	}

	function onClickSave() {
		if(props.data === undefined) {
			props.onSave(
				userData.firstName,
				userData.lastName,
				userData.email,
				userData.password === undefined ?  null : userData.password,
				userData.passwordConfirm === undefined ?  null : userData.passwordConfirm
			);
		} else {
			props.onSave(
				props.data.id,
				userData.firstName,
				userData.lastName,
				userData.email,
				userData.password === undefined ?  null : userData.password,
				userData.passwordConfirm === undefined ?  null : userData.passwordConfirm
			);
		}
	}

	function onClickLockStatusChange() {
		if(userData.isLocked) {
			props.onUnlock(props.data.id);
		} else {
			props.onLock(props.data.id);
		}
	}

	function onClickCancel() {
		props.onCancel();
	}

	return (
		<div>
			<p>First Name: </p><TextBox onChange={handleTextBoxOnChange} name="firstName"  value={userData.firstName} placeholder="First Name" type="text"/>
			<hr/>
			<p>Last Name: </p><TextBox onChange={handleTextBoxOnChange} name="lastName"  value={userData.lastName} placeholder="Last Name" type="text"/>
			<hr/>
			<p>Email Name: </p><TextBox onChange={handleTextBoxOnChange} name="email"  value={userData.email} placeholder="Email" type="text"/>
			<hr/>
			<p>Password: </p><TextBox onChange={handleTextBoxOnChange} name="password"  value={userData.password} placeholder="Password" type="password"/>
			<hr/>
			<p>Password Confirmation: </p><TextBox onChange={handleTextBoxOnChange} name="passwordConfirm"  value={userData.passwordConfirm} placeholder="Password Confirmation" type="password"/>
			<hr/>

			{ props.onLock !== null && <Button onClick={onClickLockStatusChange}>{userData.isLocked ? "Unlock" : "Lock"}</Button>}
			<hr/>
			<Button onClick={onClickSave}>Save</Button>
			<br/>
			<Button onClick={onClickCancel}>Cancel</Button>
		</div>
	);
}